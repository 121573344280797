import { Component, OnDestroy, OnInit } from '@angular/core';
import { id } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';
import * as _ from 'lodash';
import { findIndex } from 'lodash';
import { Subscription, first, last } from 'rxjs';
import {
  AppUser,
  Exercise,
  TrainingPlan,
  convertDateObject,
} from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';
import { ExerciseService } from 'src/app/services/exercise.service';
import { LogService } from 'src/app/services/log.service';
import { TrainingplanService } from 'src/app/services/trainingplan.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-coach-assigned-trainigplans',
  template: `
    <div class="py-4 flex flex-row w-full justify-between items-center">
      <div class="flex w-full flex-col">
        <label
          for="search"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Search by Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder="Enter Client Name..."
          />
        </div>
      </div>

      <div>
        <button
          (click)="onPatchPlanToggle()"
          type="button"
          class="mx-4 whitespace-nowrap inline-flex items-center rounded-md flex-row border border-transparent bg-teal-600 px-2 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
        >
          Patch Plans
        </button>
      </div>
    </div>

    <ul role="list" class="space-y-3 ">
      <li
        *ngFor="let client of displayClients; let i = index"
        class="w-fit min-w-full bg-white px-4 py-4 shadow rounded-md px-6 justify-between"
      >
        <div
          class="flex w-full justify-between items-center flex-col lg:flex-row"
        >
          <div
            *ngIf="!userContentLoaded"
            class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
          >
            <ngx-skeleton-loader
              appearance="circle"
              [theme]="{ width: '32px', height: '32px', margin: 0 }"
              class="absolute"
            ></ngx-skeleton-loader>
          </div>
          <div class="flex flex-row items-center justify-between w-7/12">
            <div
              class="flex flex-col xl:flex-row  items-center justify-between w-full"
            >
              <div class="flex flex-row">
                <img
                  *ngIf="userContentLoaded"
                  class="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300"
                  src="{{
                    client.imgUrl ||
                      '.././assets/images/default-person-image.webp'
                  }}"
                />
                <h3 class="pl-2 flex flex-col">
                  <span> {{ client.displayName }}</span>
                </h3>
              </div>
              <span
                class="italic text-sm text-gray-400 pl-2 align-bottom pt-4 lg:pt-0"
                >{{ client.email }}</span
              >
            </div>
            <button
              [routerLink]="['/trainingplan-assign/new']"
              [queryParams]="{ userId: client.id }"
              type="button"
              class="mx-4 whitespace-nowrap inline-flex items-center rounded-md flex-row border border-transparent bg-teal-600 px-2 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
            >
              + new Plan
            </button>
          </div>
          <div class="flex w-6/12 flex-col justify-end">
            <ul role="list" class="space-y-3 ">
              <li
                *ngFor="let id of client.trainingplanIds"
                class="w-full min-w-full flex flex-col items-center xl:flex-row bg-gray-50 px-4 py-4 shadow rounded-md px-6 justify-between"
              >
                <div
                  class="flex flex-row xl:flex-col justify-center text-center"
                >
                  <span class="text-sm text-gray-400 mx-2 whitespace-nowrap">{{
                    getTPLastEdit(id) | date: 'dd-MM-yyyy'
                  }}</span>
                  <span class="text-sm text-gray-400 mx-2">{{
                    getTPLastEdit(id) | date: 'HH:mm'
                  }}</span>
                </div>
                <div class="mx-2">
                  <span class="text-sm text-gray-400 whitespace-nowrap">{{
                    getTPName(id)
                  }}</span>
                </div>
                <div *ngIf="false">
                  <span class="text-sm text-gray-400">{{ id }}</span>
                </div>
                <div
                  class="flex justify-center xl:justify-between flex-col xl:flex-row mx-2 items-center"
                >
                  <button
                    type="button"
                    (click)="onDeleteTrainingplan(id, client)"
                    class="inline-flex w-fit items-center xl:mr-4 rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    [routerLink]="['/trainingplan-assign/edit']"
                    [queryParams]="{ id: id }"
                    class="inline-flex w-fit items-center mt-2 xl:mt-0 rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
                  >
                    Edit
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>

    <ul class="pt-4" *ngIf="loadedLegacy && legacyTPs && legacyTPs.length > 0">
      <h1>Legacy Trainingplans - feel free to delete them</h1>
      <li *ngFor="let tp of legacyTPs">
        {{ tp.trainingPlanName }}

        <button
          type="button"
          (click)="deleteLegacyPlan(tp)"
          class="inline-flex w-fit items-center xl:mr-4 rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
        >
          Delete
        </button>
      </li>
    </ul>
    <div>
      <app-simple-modal
        *ngIf="deletePlanToggle"
        (close)="onDeletePlan($event)"
        [positiveButtonText]="'Delete'"
        [negativeButtonText]="'Cancel'"
        [title]="'Delete Trainingplan?'"
        [message]="'Do you want to delete this Trainingplan?'"
      >
        ></app-simple-modal
      >
    </div>
    <div>
      <app-simple-modal
        *ngIf="deleteLegacyPlanToggle"
        (close)="onDeleteLegacyPlan($event)"
        [positiveButtonText]="'Delete'"
        [negativeButtonText]="'Cancel'"
        [title]="'Delete Legacy Trainingplan?'"
        [message]="'Do you want to delete this Legacy Trainingplan?'"
      >
        ></app-simple-modal
      >
    </div>
    <div>
      <app-simple-modal
        *ngIf="patchPlansToggle"
        (close)="onPatchPlans($event)"
        [positiveButtonText]="'Patch'"
        [negativeButtonText]="'Cancel'"
        [title]="'Patch Videolinks for Trainingplans?'"
        [message]="'Do you want to patch Videolinks for Trainingplans?'"
      >
        ></app-simple-modal
      >
    </div>
    <div *ngIf="activePatchingToggler">
      <app-loader-modal
        [loadingText]="'Patching Trainingplans...'"
      ></app-loader-modal>
    </div>
    <div *ngIf="toggleNotification">
      <app-notification
        [messageHeader]="msgHeader"
        [messageBody]="msgBody"
        (onButtonClick)="tglNotification()"
      ></app-notification>
    </div>
  `,
  styles: [],
})
export class CoachAssignedTrainigplansComponent implements OnInit, OnDestroy {
  trainingPlanSubscription: Subscription | undefined;
  userSubscription: Subscription | undefined;
  clientSubscription: Subscription | undefined;
  trainingPlans: TrainingPlan[] = [];
  legacyTPs: TrainingPlan[] = [];

  user: AppUser | undefined;
  clientDatabase: AppUser[] = [];
  displayClients: AppUser[] = [];

  selectedPlan: TrainingPlan = {};
  deletePlanToggle: boolean = false;
  deleteLegacyPlanToggle: boolean = false;
  legacyPlan: TrainingPlan = {};
  userContentLoaded = false;
  loadedLegacy = false;

  searchNameValue = '';
  selectedClient: AppUser | undefined;
  selectedId: string | undefined;

  exercisesDatabase: Exercise[] | undefined = undefined;
  exerciseMapBase = new Map<string, Exercise>();

  patchPlansToggle = false;
  activePatchingToggler = false;

  toggleNotification = false;
  msgHeader = '';
  msgBody = '';

  constructor(
    private trainingPlanService: TrainingplanService,
    private exerciseService: ExerciseService,
    private userService: UserService,
    protected auth: AuthService,
  ) {}
  ngOnInit(): void {
    this.trainingPlanSubscription = this.trainingPlanService
      .getAllTrainingplansForCoach()
      .subscribe((data) => {
        this.fixDatesInTrainingPlans(data);
        this.trainingPlans = data;
      });

    this.userSubscription = this.auth.appUser$
      .pipe()
      .subscribe((appUser) => (this.user = appUser as AppUser));

    if (this.user && this.user.clientIds) {
      this.getClientDatabase(this.user.clientIds);
    }
    if (this.user && this.user.coachSpotId) {
      this.getCoachSpot(this.user.coachSpotId);
    }
  }

  tglNotification() {
    this.toggleNotification = !this.toggleNotification;
  }

  onPatchPlanToggle() {
    this.patchPlansToggle = !this.patchPlansToggle;
  }

  onPatchPlans(value: boolean) {
    if (value) {
      this.activePatchingToggler = true;

      this.exerciseService
        .getAllExercisesForUser()
        .pipe(first())
        .subscribe((exercises) => {
          if (exercises) {
            let exerciseMapBase = new Map<string, Exercise>();
            exercises.forEach((ex) => {
              exerciseMapBase.set(ex.id as string, ex);
            });
            this.msgBody = '';
            this.onPatchExercises(exerciseMapBase);
            this.activePatchingToggler = false;
            this.msgHeader = 'Plans patched!';
            if (this.msgBody.length > 0) {
              this.tglNotification();
            }
          }
        });
    }

    this.activePatchingToggler = false;
    this.onPatchPlanToggle();
  }

  onPatchExercises(exerciseMap: Map<string, Exercise>) {
    if (exerciseMap) {
      this.trainingPlans.forEach((plan) => {
        let shouldPatchPlan = false;
        plan.mesoCycles?.forEach((meso) => {
          meso.trainingDays?.forEach((traininDay) => {
            traininDay.trainingSlots?.forEach((trainingSlot) => {
              if (trainingSlot.exerciseId) {
                if (exerciseMap.has(trainingSlot.exerciseId as string)) {
                  const ex = exerciseMap.get(trainingSlot.exerciseId as string);

                  if (ex?.exerciseVideoURL) {
                    trainingSlot.exerciseVideoLink = ex.exerciseVideoURL;
                    shouldPatchPlan = true;
                  }
                }
              } else {
                exerciseMap.forEach((value, key) => {
                  if (
                    value.exerciseName === trainingSlot.exerciseName &&
                    value.exerciseDescription ===
                      trainingSlot.exerciseDescription &&
                    value.exerciseVideoURL
                  ) {
                    trainingSlot.exerciseVideoLink = value.exerciseVideoURL;
                    shouldPatchPlan = true;
                  }
                });
              }
            });
          });
        });

        if (shouldPatchPlan) {
          this.msgBody += plan.trainingPlanName + ' - ✅' + '<br>';
          this.trainingPlanService.update(plan, plan.clientId as string);
        }
      });
    }
  }

  getCoachSpot(clientId: string) {
    this.userService
      .getUserFromDatabase(clientId)
      .pipe(first())
      .subscribe((client) => {
        if (client) {
          if (this.clientDatabase.some((e) => e.id === client.id)) {
            const i = this.clientDatabase.indexOf(client);
            this.clientDatabase[i] = client;
          } else {
            this.clientDatabase.push(client);
          }
          this.displayClients = this.clientDatabase;
        }
      });
  }

  getClientDatabase(clientIds: string[]) {
    clientIds.forEach((id) => {
      this.clientSubscription = this.userService
        .getUserFromDatabase(id)
        .subscribe((client) => {
          if (client && client?.coachId === this.user!.id) {
            const index = this.clientDatabase.findIndex(
              (clientObj) => clientObj.id === client.id,
            );
            if (index !== -1) {
              this.clientDatabase[index] = client;
            } else {
              this.clientDatabase.push(client);
            }
          }

          this.displayClients = this.clientDatabase;
          this.userContentLoaded = true;
          this.removeLegacyPlans();
        });
    });
  }

  removeLegacyPlans() {
    this.legacyTPs = _.clone(this.trainingPlans);
    this.displayClients.forEach((client) => {
      client.trainingplanIds?.forEach((tp) => {
        const index = this.legacyTPs.findIndex((item) => item.id === tp);

        if (index !== -1) {
          this.legacyTPs.splice(index, 1);
        }
      });
    });
    this.loadedLegacy = true;
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayClients = this.clientDatabase;
    } else {
      this.displayClients = this.clientDatabase.filter((client) =>
        client.displayName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayClients = this.clientDatabase;
    }
  }

  ngOnDestroy(): void {
    this.trainingPlanSubscription?.unsubscribe();
    this.clientSubscription?.unsubscribe();
    this.userSubscription?.unsubscribe();
  }

  fixDatesInTrainingPlans(tpArray: TrainingPlan[]) {
    tpArray.forEach((tp) => {
      tp.lastEdit = convertDateObject(tp.lastEdit as Date);
    });
  }

  getClientNameById(id: string) {
    let client = this.clientDatabase.find((client) => client.id === id);

    return client?.displayName;
  }

  getTPName(id: string | undefined) {
    if (id) {
      let tp = this.trainingPlans.find((tp) => tp.id === id);

      return tp?.trainingPlanName;
    }

    return '';
  }

  getTPLastEdit(id: string | undefined) {
    if (id) {
      let tp = this.trainingPlans.find((tp) => tp.id === id);
      return tp?.lastEdit;
    }

    return '';
  }

  deleteLegacyPlan(tp: TrainingPlan) {
    this.legacyPlan = tp;
    this.deleteLegacyPlanToggle = true;
  }
  onDeleteLegacyPlan(event: boolean) {
    if (event && this.legacyPlan) {
      this.trainingPlanService.delete(this.legacyPlan);
      this.legacyTPs.splice(
        this.legacyTPs.findIndex((item) => item.id === this.legacyPlan.id),
        1,
      );
    }

    this.deleteLegacyPlanToggle = false;
  }

  onDeleteTrainingplan(id: string, client: AppUser) {
    this.selectedPlan = this.trainingPlans.find(
      (tp) => tp.id === id,
    ) as TrainingPlan;

    this.selectedId = id;
    this.selectedClient = client;
    this.deletePlanToggle = true;
  }

  onDeletePlan(event: boolean) {
    if (event) {
      if (this.selectedPlan) {
        this.trainingPlanService.delete(this.selectedPlan);

        const index = this.selectedClient?.trainingplanIds?.findIndex(
          (id) => id === this.selectedPlan.id,
        );
        this.selectedClient?.trainingplanIds?.splice(Number(index), 1);
      } else {
        const index = this.selectedClient?.trainingplanIds?.findIndex(
          (id) => id === this.selectedId,
        );
        this.selectedClient?.trainingplanIds?.splice(Number(index), 1);
      }

      this.userService.saveUserData(this.selectedClient as AppUser);
    }

    this.deletePlanToggle = false;
  }

  oldTps: TrainingPlan[] = [];

  getAllOld() {
    this.trainingPlanService
      .getAllOLDTrainingplansForCoach()
      .subscribe((data) => {
        this.oldTps = data;
        console.log(this.oldTps);
      });
  }

  saveNew() {
    if (this.oldTps.length > 0) {
      this.trainingPlanService.saveToNewDatabase(this.oldTps);
    }
  }

  deleteOld() {
    if (this.oldTps.length > 0) {
      this.trainingPlanService.deleteOLD(this.oldTps);
    }
  }
}
