import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import {
  AppUser,
  MesoCycle,
  TrainingLog,
  TrainingPlan,
} from 'src/app/core/thecoach';
import { LogService } from 'src/app/services/log.service';
import { TrainingplanService } from 'src/app/services/trainingplan.service';

@Component({
  selector: 'app-simulate-client',
  template: `
    <div class="text-3xl font-bold text-teal-600">-- CLIENT SIMULATION --</div>

    <div
      class="w-[30rem]"
      *ngIf="selectedClient && loadedTrainingPlan && loadedTrainingLog"
    >
      <app-training-table-dashboard
        [loadedTrainingPlan]="loadedTrainingPlan"
        [selectedLog]="loadedTrainingLog"
        [client]="selectedClient"
      ></app-training-table-dashboard>

      <div class="text-3xl font-bold text-teal-600">
        -- CLIENT TP SIMULATION --
      </div>
      <app-customer-trainingplans
        [idOverride]="loadedTrainingPlan.id"
      ></app-customer-trainingplans>
    </div>
  `,
  styles: [],
})
export class SimulateClientComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedClient: AppUser | undefined = undefined;

  loadedTrainingLog: TrainingLog | null = null;
  loadedTrainingPlan: TrainingPlan | null = null;
  loadedTrainingPlanMesoCycle: MesoCycle | null = null;
  loadedTrainingLogMesoCycle: MesoCycle | null = null;
  trainingLogSubscription: Subscription | undefined;
  trainingplanSubscriptions: Subscription | undefined;
  constructor(
    private trainingLogService: LogService,
    private trainingPlanService: TrainingplanService,
  ) {}
  ngOnInit(): void {
    this.resetVariables();

    if (this.selectedClient) {
      this.getTrainingLog(this.selectedClient);
      this.getTrainingPlan(this.selectedClient);
      this.getTrainingPlans(this.selectedClient);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedClient']) {
      const change: SimpleChange = changes['selectedClient'];
      //console.log('Previous value:', change.previousValue);
      // console.log('Current value:', change.currentValue);

      // Example: Check if a specific field has changed
      if (
        change.previousValue !== undefined &&
        change.previousValue?.id !== change.currentValue?.id
      ) {
        // console.log('The User Changed!');
        this.ngOnDestroy();
        this.ngOnInit();
      }

      // Do other logic here based on changes
    }
  }

  resetVariables() {
    this.loadedTrainingLog = null;
    this.loadedTrainingPlan = null;
    this.loadedTrainingLogMesoCycle = null;
    this.loadedTrainingPlanMesoCycle = null;
    localStorage.removeItem('session');
  }
  ngOnDestroy(): void {
    this.trainingLogSubscription?.unsubscribe();
    this.trainingplanSubscriptions?.unsubscribe();
  }

  getTrainingLog(client: AppUser) {
    this.trainingLogSubscription = this.trainingLogService
      .getLatestLogForUser(client.id!)
      .subscribe((val) => {
        if (val) {
          this.loadedTrainingLog = val;
        }
      });
  }

  getTrainingPlan(client: AppUser) {
    this.trainingplanSubscriptions = this.trainingPlanService
      .getTrainingPlan(
        client.trainingplanIds![client.trainingplanIds!.length - 1],
        client,
      )
      .subscribe((plan) => {
        if (plan && plan.mesoCycles) {
          const lastMesoIndex = plan.mesoCycles.length - 1;
          this.loadedTrainingPlanMesoCycle = plan?.mesoCycles[lastMesoIndex];
        }
      });
  }

  getTrainingPlans(client: AppUser) {
    this.trainingplanSubscriptions = this.trainingPlanService
      .getAllTrainingplansForClient(client)
      .subscribe((plans) => {
        if (plans) {
          plans.sort((a, b) => {
            const dateA =
              a.lastEdit instanceof Timestamp
                ? a.lastEdit.toDate().getTime()
                : (a.lastEdit as Date).getTime();

            const dateB =
              b.lastEdit instanceof Timestamp
                ? b.lastEdit.toDate().getTime()
                : (b.lastEdit as Date).getTime();

            return dateB - dateA;
          });
          this.loadedTrainingPlan = plans[0];
        }
      });
  }
}
