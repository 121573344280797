import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { isSameDay } from 'date-fns';
import { Subscription } from 'rxjs';
import {
  WeightEntryData,
  AppUser,
  CustomInputType,
  TrainingLog,
  convertDateObject,
  CustomTrackingModel,
  Macros,
  calculateKcalFromMacros,
  calcuclateDailydifferenceMaster,
  calculateDifferenceFromStartMaster,
  calculateFiveDayAverageMaster,
  calculateSevenDayAverageMaster,
  calculateRoglDailyMaster,
  calculateRoglWeeklyMaster,
} from 'src/app/core/thecoach';
import { CustomTrackingModelService } from 'src/app/services/custom-tracking-model.service';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-statistics-wlog-table',
  template: `
    <div *ngIf="client">
      <div class="pt-2">
        <div class="overflow-x-auto -mx-3">
          <table class="divide-y divide-gray-300  w-full ">
            <thead app-statistics-wlog-tableheader [client]="client"></thead>

            <tbody
              app-statistics-wlog-tablebody
              [client]="client"
              [weightLogData]="table"
              class=""
            ></tbody>
          </table>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class StatisticsWlogTableComponent {
  @Input() weightLogData!: WeightEntryData[] | null;
  @Input() client!: AppUser | undefined;

  table: WeightEntryData[] = [];

  trainingLogSubscription: Subscription | undefined;
  loadedTrainingLog: TrainingLog | null = null;

  constructor(
    protected ctmService: CustomTrackingModelService,
    private cdr: ChangeDetectorRef,
    private trainingLogService: LogService,
  ) {}

  ngOnInit(): void {
    //this.splitArray = this.formatArrays(this.weightLogData!);
    if (this.weightLogData) this.table = this.weightLogData;

    if (this.client) this.getTrainingLog(this.client);
  }

  getTrainingLog(client: AppUser) {
    this.trainingLogSubscription = this.trainingLogService
      .getLatestLogForUser(client.id!)
      .subscribe((val) => {
        if (val) {
          this.loadedTrainingLog = val;
          this.syncTrainingLogWithWeightEntrySimple(
            this.loadedTrainingLog,
            this.table,
          );
        }
      });
  }

  syncTrainingLogWithWeightEntrySimple(
    trainingLog: TrainingLog,
    table: WeightEntryData[],
  ) {
    table.forEach((day) => {
      trainingLog.mesoCycle?.forEach((meso) => {
        meso.trainingDays?.find((td) => {
          const logStartDate = convertDateObject(td.startDate as Date);
          const weightDate = convertDateObject(day.date as Date);
          if (
            logStartDate &&
            weightDate &&
            isSameDay(logStartDate, weightDate)
          ) {
            day.sessionName = td.trainingDayName;
          }
        });
      });
    });
  }

  syncTrainingLogWithWeightEntry(
    trainingLog: TrainingLog,
    weightEntryData: WeightEntryData[][],
  ) {
    weightEntryData.forEach((table) => {
      table.forEach((day) => {
        trainingLog.mesoCycle?.forEach((meso) => {
          meso.trainingDays?.find((td) => {
            const logStartDate = convertDateObject(td.startDate as Date);
            const weightDate = convertDateObject(day.date as Date);
            if (
              logStartDate &&
              weightDate &&
              isSameDay(logStartDate, weightDate)
            ) {
              day.sessionName = td.trainingDayName;
            }
          });
        });
      });
    });
  }

  formatArrays(wldata: WeightEntryData[]) {
    let j = 0;
    let testarray: WeightEntryData[][] = [[]];
    wldata = wldata.reverse();
    wldata.forEach((data, index) => {
      if (index === 0) {
        testarray[j].push(data);
      } else {
        const currentCustomTrackingId = data.customTrackingLog?.id;
        const previousCustomTrackingId =
          wldata[index - 1].customTrackingLog?.id;
        if (currentCustomTrackingId !== previousCustomTrackingId) {
          // Start a new sub-array and add the data to it
          if (currentCustomTrackingId === undefined || null) {
            testarray[j].push(data);
          } else {
            if (
              testarray[j].find(
                (item) =>
                  item.customTrackingLog?.id === currentCustomTrackingId,
              )
            ) {
              testarray[j].push(data);
            } else {
              j++;
              testarray[j] = [data];
            }
          }
        } else {
          testarray[j].push(data);
        }
      }
    });

    testarray.forEach((table) => {
      table = table.reverse();
    });

    if (testarray.length > 1) {
      let tmp = testarray[1].concat(testarray[0]);
      tmp.sort((a, b) => b.date!.getTime() - a.date!.getTime());

      testarray.shift();
      testarray[0] = tmp;
    }

    // console.log(testarray);
    return testarray.reverse();
  }

  findCTMForTable(tableData: WeightEntryData[]) {
    let ctm: CustomTrackingModel | undefined;

    ctm = tableData.find((data) => data.customTrackingLog)?.customTrackingLog;

    return ctm;
  }

  calculateKcal(macros: Macros) {
    return calculateKcalFromMacros(macros);
  }

  calculateDailydifference(
    weight: number,
    index: number,
    client: AppUser,
  ): string {
    return calcuclateDailydifferenceMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateDifferenceFromStart(index: number, client: AppUser): string {
    return calculateDifferenceFromStartMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateFiveDayAverage(index: number, client: AppUser): string {
    return calculateFiveDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateSevenDayAverage(index: number, client: AppUser): string {
    return calculateSevenDayAverageMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglDaily(weight: number, index: number, client: AppUser): string {
    return calculateRoglDailyMaster(
      weight,
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }

  calculateRoglWeekly(index: number, client: AppUser): string {
    return calculateRoglWeeklyMaster(
      index,
      client,
      this.weightLogData as WeightEntryData[],
    );
  }
}
