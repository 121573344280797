import { Injectable } from '@angular/core';
import {
  AppUser,
  TemplateTrainingDay,
  TrainingLog,
  UserRole,
  convertDateObject,
  isValidDate,
  sortTrainingLogsToLatest,
} from '../core/thecoach';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { Observable, first, map, of, switchMap, take } from 'rxjs';
import { UserService } from './user.service';
import { isWithinInterval } from 'date-fns';
import { isNaN } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private db: AngularFirestore,
    private auth: AuthService,
    private userService: UserService,
  ) {}

  getLog(trainingplanId: string) {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of(undefined);
        }

        if (appUser.role === UserRole.Admin) {
          return this.db
            .collection<TrainingLog>('/trainingsLogs', (ref) => {
              return ref.where('trainingPlanId', '==', trainingplanId).limit(1);
            })
            .valueChanges()
            .pipe(
              map((data) => {
                return data[0];
              }),
            );
        } else {
          return this.db
            .collection<TrainingLog>('/trainingsLogs', (ref) => {
              return ref
                .where('clientId', '==', appUser.id)
                .where('trainingPlanId', '==', trainingplanId)
                .limit(1);
            })
            .valueChanges()
            .pipe(
              map((data) => {
                return data[0];
              }),
            );
        }
      }),
      take(1),
    );
  }

  getLimitLog(
    trainingPlanId: string,
    meso: string,
    limit: number,
    startAfter?: Date,
  ) {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of(undefined);
        }

        return this.db
          .collection<TrainingLog>('/trainingsLogs', (ref) => {
            let query = ref
              .where('clientId', '==', appUser.id)
              .where('trainingPlanId', '==', trainingPlanId);

            if (meso) {
              query = query.where('mesoCycle', 'array-contains', {
                mesoname: meso,
              });
            }

            if (startAfter) {
              query = query.where('trainingDays.startDate', '>=', startAfter);
            }

            query = query.limit(limit);

            return query;
          })
          .valueChanges()
          .pipe(
            map((data) => {
              return data[0];
            }),
          );
      }),
      take(1),
    );
  }

  getLatestLogsForUser(limit: number): Observable<TrainingLog[]> {
    return this.auth.appUser$.pipe(
      switchMap((user) => {
        if (user) {
          return this.db
            .collection<TrainingLog>('/trainingsLogs', (ref) => {
              return ref
                .where('clientId', '==', user.id)
                .orderBy('timestamp', 'desc')
                .limit(limit);
            })
            .valueChanges();
        } else {
          return [];
        }
      }),
    );
  }

  getLatestLogForCurrentUser() {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of(undefined);
        }

        let trainingId = '';
        if (appUser.traininglogIds && appUser.traininglogIds.length > 1) {
          trainingId = appUser.traininglogIds?.at(
            appUser.traininglogIds.length - 1,
          ) as string;
        } else {
          trainingId = appUser.traininglogIds?.at(0) as string;
        }

        return this.db

          .collection<TrainingLog>('/trainingsLogs', (ref) => {
            return ref.where('clientId', '==', appUser.id);
          })
          .doc(trainingId)
          .valueChanges()
          .pipe(
            map((trainingLog: TrainingLog | undefined) => {
              if (
                trainingLog &&
                trainingLog.mesoCycle &&
                trainingLog.mesoCycle.length > 0
              ) {
                trainingLog.mesoCycle.forEach((meso) => {
                  if (meso.trainingDays && meso.trainingDays.length > 0) {
                    meso.trainingDays.forEach((day) => {
                      if (day.startDate)
                        day.startDate = convertDateObject(day.startDate);
                      if (day.endDate)
                        day.endDate = convertDateObject(day.endDate);
                    });
                  }
                });

                if (trainingLog.lastEdit) {
                  trainingLog.lastEdit = convertDateObject(
                    trainingLog.lastEdit,
                  );
                }

                return trainingLog;
              } else {
                return null;
              }
            }),
          );
      }),
    );
  }

  getAllLogsForUserBetweenDates(
    user: AppUser,
    startOfDay: Date,
    endOfDay: Date,
  ) {
    return this.db
      .collection<TrainingLog>('/trainingsLogs', (ref) => {
        return ref.where('clientId', '==', user.id as string);
      })
      .valueChanges()
      .pipe(
        map((trainingLogs: TrainingLog[]) => {
          if (trainingLogs) {
            //console.log('datelog', trainingLogs);
            const logsInInterval: TemplateTrainingDay[] = [];
            trainingLogs.some((trainingLog) => {
              trainingLog.mesoCycle?.some((meso) =>
                meso.trainingDays?.forEach((td) => {
                  td.startDate = convertDateObject(td.startDate as Date);
                  td.endDate = convertDateObject(td.endDate as Date);

                  if (
                    isWithinInterval(td.startDate as Date, {
                      start: startOfDay,
                      end: endOfDay,
                    })
                  ) {
                    return logsInInterval.push(td);
                  }
                  return null;
                }),
              );
            });

            return logsInInterval;
          } else {
            return null;
          }
        }),
      );
  }

  getTrainingLogForUserLatest(clientId: string) {
    return this.db
      .collection<TrainingLog>('/trainingsLogs', (ref) => {
        return ref.where('clientId', '==', clientId);
      })
      .valueChanges()
      .pipe(
        map((traininglogs: TrainingLog[]) => {
          if (traininglogs) {
            traininglogs.some((traininglog) => {
              traininglog.mesoCycle?.forEach((meso) => {
                meso.trainingDays?.forEach((trainingDay) => {
                  trainingDay.endDate = convertDateObject(
                    trainingDay.endDate as Date,
                  );
                  trainingDay.startDate = convertDateObject(
                    trainingDay.startDate as Date,
                  );
                });
                meso.trainingDays?.sort(
                  (a, b) => b!.startDate!.getTime() - a!.startDate!.getTime(),
                );
              });
            });

            if (traininglogs.length > 1) {
              traininglogs = sortTrainingLogsToLatest(traininglogs);
              //console.log('latestlog', latestLog)
            }

            return traininglogs;
          } else {
            return [];
          }
        }),
      );
  }

  getLatestLogForUser(clientId: string) {
    return this.db

      .collection<TrainingLog>('/trainingsLogs', (ref) => {
        return ref.where('clientId', '==', clientId);
      })
      .valueChanges()
      .pipe(
        map((traininglogs: TrainingLog[]) => {
          if (traininglogs) {
            traininglogs.some((traininglog) => {
              traininglog.mesoCycle?.forEach((meso) => {
                meso.trainingDays?.forEach((trainingDay) => {
                  trainingDay.endDate = convertDateObject(
                    trainingDay.endDate as Date,
                  );
                  trainingDay.startDate = convertDateObject(
                    trainingDay.startDate as Date,
                  );
                });
                meso.trainingDays?.sort(
                  (a, b) => b!.startDate!.getTime() - a!.startDate!.getTime(),
                );
              });
            });

            if (traininglogs.length > 1) {
              traininglogs = sortTrainingLogsToLatest(traininglogs);
              //console.log('latestlog', latestLog)
            }

            return traininglogs[0];
          } else {
            return undefined;
          }
        }),
      );
  }

  getLastTrainingStartDate(log: TrainingLog) {
    const mesoCycle = log.mesoCycle;
    if (!mesoCycle || mesoCycle.length === 0) {
      return 0; // No mesoCycle or empty, fallback value
    }

    const lastCycle = mesoCycle[mesoCycle.length - 1];
    if (!lastCycle.trainingDays || lastCycle.trainingDays.length === 0) {
      return 0; // No trainingDays or empty, fallback value
    }

    const lastTrainingDay =
      lastCycle.trainingDays[lastCycle.trainingDays.length - 1];
    return lastTrainingDay.startDate ? lastTrainingDay.startDate.getTime() : 0; // Use startDate if it exists
  }

  saveLog(tl: TrainingLog) {
    if (!tl.id) {
      const id = this.db.createId();
      tl.id = id;
    }

    tl.mesoCycle?.forEach((meso) => {
      if (!meso.id) {
        delete meso.id;
      }

      meso.trainingDays?.forEach((td) => {
        if (!td.trainingDayId) {
          delete td.trainingDayId;
        }
      });
    });

    this.saveTrainingLogToUser(tl).subscribe();

    tl.lastEdit = new Date();
    return this.db
      .collection<TrainingLog>('/trainingsLogs')
      .doc(tl.id)
      .set(Object.assign({}, tl));
  }

  saveTrainingLogToUser(tl: TrainingLog) {
    tl.lastEdit = new Date();
    return this.auth.appUser$.pipe(
      first(),
      switchMap((appUser) => {
        if (!appUser) {
          return of(undefined);
        }

        if (!appUser.traininglogIds) appUser.traininglogIds = [];

        if (!appUser.traininglogIds?.includes(tl.id!))
          appUser.traininglogIds?.push(tl.id!);

        return this.userService.saveUserData(appUser);
      }),
    );
  }

  updateLog(tl: TrainingLog) {
    const now = new Date();

    if (isNaN(now.getTime())) {
      throw new Error('Invalid Daate encountered');
    }

    tl.lastEdit = now;

    this.checkEndDates(tl);

    return this.db
      .collection<TrainingLog>('/trainingsLogs')
      .doc(tl.id)
      .set(Object.assign({}, tl), { merge: true });
  }

  checkEndDates(tl: TrainingLog) {
    tl.mesoCycle?.forEach((meso) => {
      meso.trainingDays?.forEach((trainingDay) => {
        if (!isValidDate(trainingDay.endDate) && trainingDay.startDate) {
          trainingDay.endDate = new Date(
            trainingDay.startDate?.getTime() + 10 * 60000,
          );
        }
      });
    });
  }

  deleteSession(sessionId: string) {
    return this.auth.appUser$.pipe(
      switchMap((appUser) => {
        if (!appUser) {
          return of(undefined);
        }

        return this.db
          .collection<TrainingLog>('/trainingsLogs', (ref) => {
            return ref.where('clientId', '==', appUser.id);
          })
          .valueChanges()
          .pipe(
            map((data) => {
              return data[0];
            }),
          );
      }),
    );
  }

  getLatestMesoCycleForUser(clientId: string, logId: string) {
    return this.db
      .collection<TrainingLog>('/trainingsLogs', (ref) => {
        return ref.where('clientId', '==', clientId);
      })
      .doc(logId)
      .valueChanges()
      .pipe(
        map((trainingLog: TrainingLog | undefined) => {
          if (
            trainingLog &&
            trainingLog.mesoCycle &&
            trainingLog.mesoCycle.length > 0
          ) {
            trainingLog.mesoCycle = trainingLog.mesoCycle.sort((a, b) => {
              const startDateA = convertDateObject(
                a.trainingDays?.[0]?.startDate as Date,
              );
              const startDateB = convertDateObject(
                b.trainingDays?.[0]?.startDate as Date,
              );

              if (startDateA && startDateB) {
                return startDateB.getTime() - startDateA.getTime();
              }

              // If either startDateA or startDateB is undefined, handle it accordingly.
              // For example, you could decide how to handle this case based on your requirements.
              // For now, let's assume we want to keep the original order if any of the dates is undefined.
              return 0;
            });

            let lastMesoCycle = trainingLog.mesoCycle[0];
            lastMesoCycle.trainingDays?.forEach((day) => {
              if (day.startDate)
                day.startDate = convertDateObject(day.startDate);

              if (day.endDate) day.endDate = convertDateObject(day.endDate);
            });
            return lastMesoCycle;
          } else {
            return null;
          }
        }),
      );
  }

  getTrainingLogsForUser(clientId: string) {
    return this.db
      .collection<TrainingLog>('/trainingsLogs', (ref) => {
        return ref.where('clientId', '==', clientId);
      })
      .valueChanges()
      .pipe(
        map((traininglogs: TrainingLog[]) => {
          if (traininglogs) {
            traininglogs.forEach((traininglog) => {
              traininglog.mesoCycle?.forEach((meso) => {
                meso.trainingDays?.forEach((trainingDay) => {
                  trainingDay.endDate = convertDateObject(
                    trainingDay.endDate as Date,
                  );
                  trainingDay.startDate = convertDateObject(
                    trainingDay.startDate as Date,
                  );
                });
              });
            });
            return traininglogs;
          } else {
            return [];
          }
        }),
      );
  }

  getTrainingLogCollectionForUser(clientId: string) {
    return this.db.collection<TrainingLog>('/trainingsLogs').doc(clientId);
  }
}
